<template>
  <div
    class="
      login
      bg-white
      border border-eee
      relative
      flex
      rounded
      overflow-hidden
    "
  >
    <span class="absolute top-20 left-20 z-10">
      <router-link to="/auth/register" class="text-white text-sm">
        注册
      </router-link>
    </span>
    <div class="absolute top-0 left-0 flag border-primary"></div>
    <div class="divider bg-eee"></div>
    <div class="flex flex-col justify-center login-form px-40">
      <div class="text-28 font-bold text-111 text-center mb-60">登录</div>
      <a-form-model ref="container" :model="model" :rules="rules">
        <a-form-model-item prop="username">
          <gf-input
            size="large"
            v-model="model.username"
            placeholder="请输入邮箱/手机/统一社会信用码"
          ></gf-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input-password
            v-on:keyup.enter="login"
            size="large"
            type="password"
            v-model="model.password"
            placeholder="请输入登录密码"
          ></a-input-password>
        </a-form-model-item>
      </a-form-model>
      <div class="text-right mt-10 mb-20">
        <router-link to="/auth/forget" class="text-999 text-xs">
          忘记密码
        </router-link>
      </div>
      <a-button @click="login" class="w-full" type="primary" size="large"
        >登录</a-button
      >
    </div>
    <div class="login-desc flex justify-center items-center">
      <img src="@/assets/images/login-inset.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { login } from "@/api";
import { localSet } from "@/utils/storage.js";
import { getUserInfo } from "@/api/user";
import { validateEmail } from "@/utils/validator.js";
function generateRole(res) {
  // 1 普通用户 2 专家用户 3 未认证企业用户 4 已认证企业用户 5 已认证企业会员用户 6 管理员 7 超级管理员
  if (res.type === 0) {
    return 7;
  } else if (res.type === 1) {
    return 6;
  } else if (res.type === 2) {
    if (res.is_expert) return 2;
    return 1;
  } else if (res.type === 3) {
    if (res.authenticate === 2) {
      if (res.is_member) {
        return 5;
      } else {
        return 4;
      }
    } else {
      return 3;
    }
  }
}
export default {
  name: "login",
  components: {},
  data() {
    return {
      model: {},
      rules: {
        username: [
          {
            required: true,
            message: "请输入邮箱/手机/统一社会信用码",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { min: 8, max: 16, message: "登录密码长度8-16位", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    login() {
      this.$refs.container.validate((valid) => {
        if (valid) {
          login({ ...this.model })
            .then(({ code, data, msg }) => {
              const { token } = data;
              localSet("token", token);
              getUserInfo().then(({ data }) => {
                let role = generateRole(data);
                if (data.type === 3) {
                  window.location.href = "#/index";
                } else {
                  localSet("token", "");
                  this.$message.error('请使用企业账号登录');
                  this.model = {}
                }
              });
            })
            .catch(({ msg }) => {
              this.$message.error(msg);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.login {
  width: 900px;
  height: 560px;
  box-shadow: 2px 5px 32px -10px rgba(0, 0, 0, 0.2);
}
.login-form {
  width: 400px;
}
.login-desc {
  width: 500px;
}
.flag {
  width: 100px;
  height: 100px;
  border-width: 50px;
  border-width: 50px;
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.divider {
  position: absolute;
  left: 400px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 360px;
}
</style>